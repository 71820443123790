<template>
	<div class="f82m4">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>G29-Z4 ASSIST Z4</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									G29-Z4 ASSIST Z4
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div id="main-info" class="section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-6">
						<h2 class="px-2">G29-Z4 ASSIST Z4</h2>
						<h3>Concept</h3>
						<p class="p-2">
							ストリートメインでありながら、広場やショートサーキットで気軽に楽しむためにBMWの中ではライトウェイトスポーツであるG29-Z4/20i
							M-SPORTをベース車としてチョイスしました。<br />
							4気筒エンジンなのでフロントが軽快でショートホイールベース、低重心と、ベースとしてはポテンシャルが非常に高いです。コンバーチブルでありながらボディ剛性も高いところも非常に魅力的です。<br />
							TOYOTAスープラに、ほとんどのパーツ流用が可能です。<br />
							エンジンパワーはホイール計測で281馬力、吸排気とDMEチューニングだけで90馬力ほどアップしています。<br />
							低中回転域が非常にパワフルでモータースポーツを楽しむのに丁度良いぐらいのパワーです。<br />
							足回りはASSIST定番のモノボールベアリングマウントにしてタイヤを軌道通りに動かして低フリクションにすることでしなやかにタイヤの接地性をアップした上にINTRAXサスペンションでよりしなやかにコーナーリングします。<br />
							ドライブトレインではFR車には必須であるLSD、OS技研の機械式LSDをASSISTセッティングで搭載しています。これによりモータースポーツを充分に満喫することが出来ます。<br />
							Z4は風を感じながら優雅に走ることができますし、スポーツにスイッチすればモータースポーツを楽しむことができる最高のコンバーチブルカ―です。<br />
						</p>
					</div>
					<div class="col-12 col-md-6 mb-4">
						<img
							src="/img/concept-cars/z4-g29/main.jpg"
							class="img-fluid"
							alt="画像08"
						/>
						<div class="my-4">
							<table class="table table-bordered">
								<tbody>
									<tr>
										<th scope="row" colspan="2">車両</th>
										<td>BMW G29-Z4 20i M-SPORT</td>
									</tr>
									<tr>
										<th scope="row" colspan="2">仕様</th>
										<td>8速スポーツAT 右ハンドル</td>
									</tr>
									<tr>
										<th scope="row" colspan="2">ボディカラー</th>
										<td>C34 サンフランシスコレッドメタリック</td>
									</tr>
									<tr>
										<th scope="row" colspan="2">モデル</th>
										<td>2018年 3BA-HF20</td>
									</tr>
									<tr>
										<th scope="row" colspan="2">エンジン</th>
										<td>B48D 2.0L4気筒シングルターボ</td>
									</tr>
									<tr>
										<th scope="row" rowspan="3">馬力/トルク</th>
										<td>チューニング<br />タイヤ計測値</td>
										<td>302ps/6280rpm<br />46.5kg-m/2970rpm<br />(Feb. 16, 2022)</td>
									</tr>
									<tr>
										<td>ノーマル<br />タイヤ計測値</td>
										<td>
											192ps/5180rpm<br />33.2kg-m/2610rpm<br />(Jun. 17, 2020)
										</td>
									</tr>
									<tr>
										<td>純正カタログ値</td>
										<td>190ps/4500-6500rpm<br />320Nm/1450-4200rpm</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--<div id="achievement" class="block section-padding-50-0">
			<div class="container">
				<h3>Achievement</h3>
				<div class="row mb-5">
					<div class="col-12 col-md-4">
						<img
							src="/img/concept-cars/f82-m4/achievement/01.jpg"
							class="img-fluid"
							alt="画像08"
						/>
					</div>
					<div class="col-12 col-md-8">
						<div class="title">
							<h6>Hot Version 峠最強伝説 魔王 2019</h6>
							<h4>魔王 ASSIST M4</h4>
						</div>
						<p class="m-0 p-2">
							2018 年 11 月の魔王決定戦で WINNER となり、2019
							年度の魔王になりました。 1 対 1
							で峠をバトルして引き離せれば勝ち、ベタ付けされれば負け、前後入替えで
							2 回戦って勝敗を決定
							します。トーナメントで勝ち続けた者が魔王になります。
							軽量でハイパワー車が有利とされていた魔王決定戦で 1,500kg 超えの M4
							が魔王になれたのはコーナリン グフォース無しでは語れない栄光です。
						</p>
					</div>
				</div>
				<div class="row mb-4">
					<div class="col-12 col-md-4">
						<img
							src="/img/concept-cars/f82-m4/achievement/02.jpg"
							class="img-fluid"
							alt="画像08"
						/>
					</div>
					<div class="col-12 col-md-8">
						<div class="title">
							<h6>Time attack</h6>
							<h4>タイムアタック</h4>
						</div>
						<p class="m-0 p-2">
							2020 年の Attack
							筑波はフロントスポイラー損傷により正しく計測できませんでした。
						</p>
						<ul class="mb-2 ml-2">
							<li class="font-weight-bold">
								2019 年 2 月 Attack 筑波 0' 58" 818 ドライバー 谷口信輝
							</li>
							<li class="font-weight-bold">
								2018 年 2 月 Attack 鈴鹿 2' 15" 598 ドライバー 谷口信輝
							</li>
						</ul>
						<p class="m-0 p-2">
							2019 年まではノーマルタービンで 2020
							年からハイフローターボでパワーアップしていますので
							今後のタイムアタックでタイムアップは期待できそうです。
						</p>
					</div>
				</div>
			</div>
		</div>-->

		<div class="block section-padding-50-0">
			<div class="container">
				<div class="row">
					<div id="photo_gallery" class="col-12 col-sm-6">
						<h3>Photo Gallery</h3>

						<div class="row images">
							<div v-for="item in 10" :key="'photo' + item" class="col-12">
								<img
									:src="'/img/concept-cars/z4-g29/images/' + item + '.jpg'"
									:class="{
										'img-fluid': true,
										'img-thumbnail': true,
										'mb-3': true,
										'd-none': item == 1 ? false : true,
									}"
									:alt="'画像' + item"
									data-size="600x400"
								/>
								<p
									:class="{
										caution: true,
										'd-none': item == 1 ? false : true,
									}"
								>
									※ 写真をクリックするとギャラリーが見れます
								</p>
							</div>
						</div>

						<!-- Root element of PhotoSwipe. Must have class pswp. -->
						<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
							<div class="pswp__bg"></div>
							<div class="pswp__scroll-wrap">
								<div class="pswp__container">
									<div class="pswp__item"></div>
									<div class="pswp__item"></div>
									<div class="pswp__item"></div>
								</div>
								<div class="pswp__ui pswp__ui--hidden">
									<div class="pswp__top-bar">
										<div class="pswp__counter"></div>
										<button
											class="pswp__button pswp__button--close"
											title="Close (Esc)"
										></button>
										<button
											class="pswp__button pswp__button--share"
											title="Share"
										></button>
										<button
											class="pswp__button pswp__button--fs"
											title="Toggle fullscreen"
										></button>
										<button
											class="pswp__button pswp__button--zoom"
											title="Zoom in/out"
										></button>
										<div class="pswp__preloader">
											<div class="pswp__preloader__icn">
												<div class="pswp__preloader__cut">
													<div class="pswp__preloader__donut"></div>
												</div>
											</div>
										</div>
									</div>
									<div
										class="
											pswp__share-modal
											pswp__share-modal--hidden
											pswp__single-tap
										"
									>
										<div class="pswp__share-tooltip"></div>
									</div>
									<button
										class="pswp__button pswp__button--arrow--left"
										title="Previous (arrow left)"
									></button>
									<button
										class="pswp__button pswp__button--arrow--right"
										title="Next (arrow right)"
									></button>
									<div class="pswp__caption">
										<div class="pswp__caption__center"></div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!--<div id="movies" class="col-12 col-sm-6">
						<div id="movies" class="headline">
							<h3>Movies</h3>
						</div>
						<div class="row item">
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/FQ2i-uoZC9Y"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2019.02.23</small><br /><a
										href="https://youtu.be/FQ2i-uoZC9Y"
										target="_blank"
										>谷口信輝 Attack筑波
										<small class="badge badge-primary">NEW</small></a
									>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/o5ShNJdyP0k"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2018.02.09</small><br /><a
										href="https://youtu.be/o5ShNJdyP0k"
										target="_blank"
										>谷口信輝 Attack鈴鹿</a
									>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/Bt-9jA34I2U"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2016.11.26</small><br /><a
										href="https://youtu.be/Bt-9jA34I2U"
										target="_blank"
										>谷口信輝 セントラルサーキット テスト</a
									>
								</h5>
							</div>
							<div class="col-sm-6 col-12 mb-3">
								<div class="embed-responsive embed-responsive-16by9">
									<iframe
										width="560"
										height="315"
										src="https://www.youtube.com/embed/ufz326cJmbw"
										frameborder="0"
										allow="autoplay; encrypted-media"
										allowfullscreen
									></iframe>
								</div>
								<h5>
									<small>2016.12.02</small><br /><a
										href="https://youtu.be/ufz326cJmbw"
										target="_blank"
										>谷口信輝 筑波スーパーバトル</a
									>
								</h5>
							</div>
						</div>
					</div>
					-->
				</div>
			</div>
		</div>

		<div id="spec" class="block section-padding-50-0">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div id="movies" class="headline">
							<h3>Spec</h3>
						</div>

						<h4 class="mb-3">ENGINE COMPONENT</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a href="https://item.assist-kyoto.co.jp/bm3/"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/1211.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">DMEチューニング</span>
								<h5>
									<a href="https://item.assist-kyoto.co.jp/bm3/"
										>bm3 (bootmod3)
									</a>
								</h5>
								<h6>ビーエム3 (ブーモ3)</h6>
								<p>
									DMEのロックを解除してOBD2コネクターからMAPの読み書きすることができます。オリジナルデータの作成も可能でデータは専用クラウドに保存します。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://item.assist-kyoto.co.jp/plasma-direct/"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/1231.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">イグニションコイル</span>
								<h5>
									<a
										href="https://item.assist-kyoto.co.jp/plasma-direct/"
										>OKADA PROJECTS PLASMA DIRECT</a
									>
								</h5>
								<h6>オカダプロジェクツ プラズマダイレクト</h6>
								<p>強化タイプのイグニションでエンジン補正を抑えます。</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/1241.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">スパークプラグ</span>
								<h5>NGK SPARK PLUG Iridium</h5>
								<h6>NGKスパークプラグ 高熱価イリジウム</h6>
								<p>ハイブーストに適した熱価のスパークプラグ</p>
							</div>
						</div>

						<h4 class="mb-3">INTAKE</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/1321.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアクリーナーボックス</span>
								<h5>REVOLUTION Carbon Air Induction Box</h5>
								<h6>レボリューション カーボンエアインダクションボックス</h6>
								<p>パワーアップで空気量アップをスムーズに取り込みます。</p>
							</div>
						</div>

						<h4 class="mb-3">TURBO</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a
										href="https://store.assist-kyoto.co.jp/collections/intake-pipe/products/770-ftp-motorsport-%E3%83%96%E3%83%BC%E3%82%B9%E3%83%88-%E3%83%81%E3%83%A3%E3%83%BC%E3%82%B8%E3%83%91%E3%82%A4%E3%83%97-%E3%82%BB%E3%83%83%E3%83%88"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/1461.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">チャージパイプ</span>
								<h5>
									<a
										href="https://store.assist-kyoto.co.jp/collections/intake-pipe/products/770-ftp-motorsport-%E3%83%96%E3%83%BC%E3%82%B9%E3%83%88-%E3%83%81%E3%83%A3%E3%83%BC%E3%82%B8%E3%83%91%E3%82%A4%E3%83%97-%E3%82%BB%E3%83%83%E3%83%88"
										>FTP motorsport CHARGE-PIPES/INTAKE-PIPE</a
									>
								</h5>
								<h6>エフティピー モータースポーツ</h6>
								<p>ブースト圧のキープとパイプ強度アップ。</p>
							</div>
						</div>

						<h4 class="mb-3">EXHAUST</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/1851.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ダウンパイプ</span>
								<h5>ER（Evolution Racewerks）Down-Pipe</h5>
								<h6>ER（エボリューションレースワークス）ダウンパイプ</h6>
								<p>触媒を無くして排圧と排気抵抗を軽減しています。</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/future-collection/products/181-02721-exhaust-system-g294tb-for-g29-20i"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/1811.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エキゾースト</span>
								<h5>
									<a
										href="https://laptorr.com/collections/future-collection/products/181-02721-exhaust-system-g294tb-for-g29-20i"
										>LAPTORR EXHAUST SYSTEM G294tb</a
									>
								</h5>
								<h6>ラプタ エキゾーストシステム G294tb</h6>
								<p>
									フラップは純正アクチュエータで作動。エンジンチューニング向けエキゾースト。
								</p>
							</div>
						</div>

						<h4 class="mb-3">DRIVE TRAIN</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a
										href="https://item.assist-kyoto.co.jp/os-giken-lsd-tcd/"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/2911.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ディファレンシャル</span>
								<h5>
									<a
										href="https://item.assist-kyoto.co.jp/os-giken-lsd-tcd/"
										>OS-GIKEN LSD</a
									>
								</h5>
								<h6>オーエス技研 LSD</h6>
								<p>
									溶接タイプのリングギアをボルトオンタイプへ加工して装着します。
								</p>
							</div>
						</div>

						<h4 class="mb-3">FOOTWORK</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<a
										href="https://item.assist-kyoto.co.jp/intrax/"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/3001.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションキット</span>
								<h5>
									<a
										href="https://item.assist-kyoto.co.jp/intrax/"
										>INTRAX 4-WAY ARC BLACK-TITAN</a
									>
								</h5>
								<h6>イントラックス 4ウェイ ARC ブラックチタン</h6>
								<p>
									リザーバータンク、4WAY減衰調整。ストリートからタイムアタックまで使用できる超ワイドレンジの車高調サスキット。ダンパーの仕様変更は自社アップデート。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/footwork/products/312-01251-%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88%E3%83%94%E3%83%AD-%E3%83%86%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AD%E3%83%83%E3%83%89-2-pcs"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/3122.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>
									<a
										href="https://laptorr.com/collections/footwork/products/312-01251-%E3%83%95%E3%83%AD%E3%83%B3%E3%83%88%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88%E3%83%94%E3%83%AD-%E3%83%86%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AD%E3%83%83%E3%83%89-2-pcs"
										>LAPTORR MONO BALL BEARING MOUNT for Front Tension Strut</a
									>
								</h5>
								<h6>
									ラプタ モノボールベアリングマウント フロント テンションアーム
								</h6>
								<p>
									ネガキャンバー、ワイドタイヤによる高負荷にも正確な動きと低フリクションに貢献します。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/3131.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションアーム</span>
								<h5>LAPTORR MONO BALL BEARING MOUNT for Front Lower Arm</h5>
								<h6>ラプタ モノボールベアリングマウント フロント ロアアーム</h6>
								<p>
									ネガキャンバー、ワイドタイヤによる高負荷にも正確な動きと低フリクションに貢献します。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/3321.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>LAPTORR MONO BALL BEARING MOUNT for Camber Arm IN-side</h5>
								<h6>
									ラプタ モノボールベアリングマウント リア キャンバーアーム IN側
								</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/3322.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>LAPTORR MONO BALL BEARING MOUNT for Trailing Arm</h5>
								<h6>
									ラプタ モノボールベアリングマウント リア トレーリングアーム
								</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/3323.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>LAPTORR MONO BALL BEARING MOUNT for Rear Upper Arm</h5>
								<h6>ラプタ モノボールベアリングマウント リア アッパーアーム</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/3324.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションマウント</span>
								<h5>LAPTORR MONO BALL BEARING MOUNT for Rear Upper Arm 2</h5>
								<h6>
									ラプタ モノボールベアリングマウント リア アッパーアーム 2
								</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/footwork/products/333-01301-f8xm-%E3%83%AA%E3%82%A2%E3%83%AD%E3%82%A2%E3%82%A2%E3%83%BC%E3%83%A0-%E3%82%A2%E3%82%B8%E3%83%A3%E3%82%B9%E3%82%BF%E3%83%96%E3%83%AB-for-f80-82-m3-m4-f87-m2-m2c"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/3331.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">サスペンションアーム</span>
								<h5>
									<a
										href="https://laptorr.com/collections/footwork/products/333-01301-f8xm-%E3%83%AA%E3%82%A2%E3%83%AD%E3%82%A2%E3%82%A2%E3%83%BC%E3%83%A0-%E3%82%A2%E3%82%B8%E3%83%A3%E3%82%B9%E3%82%BF%E3%83%96%E3%83%AB-for-f80-82-m3-m4-f87-m2-m2c"
										>LAPTORR REAR LOWER ARM Adjustable</a
									>
								</h5>
								<h6>ラプタ リア ロアアーム 調整式</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/footwork/products/337-02271-rear-axle-mount-rigid"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/3331_1.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">リジットマウント</span>
								<h5>
									<a
										href="https://laptorr.com/collections/footwork/products/337-02271-rear-axle-mount-rigid"
										>LAPTORR RIGID MOUNT for Rear Axle</a
									>
								</h5>
								<h6>ラプタ リジッドマウント リアアクスル</h6>
								<p>リアサスの正確な動きと低フリクションに貢献します。</p>
							</div>
						</div>

						<h4 class="mb-3">TIRE</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/3701.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">タイヤ</span>
								<h5>YOKOHAMA ADVAN A052 255/40-18</h5>
								<h6>ヨコハマ アドバン A052 255/40-18</h6>
								<p>(F/R) 255/40R-18<br />ADVAN最強のラジアルタイヤ。</p>
							</div>
						</div>

						<h4 class="mb-3">WHEELS</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/3601.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ホイール</span>
								<h5>RAYS VOLKRACING TE37-SAGA 18"-10.0J</h5>
								<h6>レイズ ヴォルクレーシング TE37-SAGA 18"-10.0J</h6>
								<p>(F/R) 18x10.0J +34</p>
							</div>
						</div>

						<h4 class="mb-3">BRAKE</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/brake_front.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ブレーキシステム</span>
								<h5>ENDLESSブレーキシステム／フロント</h5>
								<h6>エンドレスブレーキシステム／フロント</h6>
								<p>
									キャリパー：<br />鍛造モノブロック6ピストン<br />ローター：<br />355ｘ32mm
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/brake_rear.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">ブレーキシステム</span>
								<h5>ENDLESSブレーキシステムインチアップキット／リア</h5>
								<h6>エンドレスブレーキシステムインチアップキット／リア</h6>
								<p>
									キャリパー：<br />純正＋ブラケット<br />ローター：<br />345ｘ20mm
									インチアップ
								</p>
							</div>
						</div>

						<h4 class="mb-3">EXTERIOR</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/5121.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアロダイナミクス</span>
								<h5>3DDesign FRONT LIP SPOILER</h5>
								<h6>スリーディデザイン フロントリップスポイラー</h6>
								<p>ボディ底面を整流。</p>
							</div>
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/5122.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">エアロダイナミクス</span>
								<h5>BMW REAR DIFFUSER for M40i</h5>
								<h6>BMW純正 リアディフューザー M40i用</h6>
								<p>LAPTORRエキゾースト4本出し装着用。</p>
							</div>
						</div>

						<h4 class="mb-3">INTERIOR</h4>
						<div class="row mb-4">
							<div class="col-sm-3 col-6">
								<img
									src="/img/concept-cars/z4-g29/tuning/5211.jpg"
									class="img-fluid"
									alt="TIRES(画像01)"
								/>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">シート</span>
								<h5>RECARO PRO RACER RMS 2700G (FRP)</h5>
								<h6>レカロ プロレーサー RMS 2700G（FRP）</h6>
								<p>
									ボディデザインとの相性は抜群。剛性、ドライビングインフォメーション、通気性に優れている。
								</p>
							</div>
							<div class="col-sm-3 col-6">
								<a
										href="https://laptorr.com/collections/interior/products/522-00702-%E3%82%B7%E3%83%BC%E3%83%88%E3%83%AC%E3%83%BC%E3%83%AB-%E3%82%BC%E3%83%AD%E3%82%AA%E3%83%95%E3%82%BB%E3%83%83%E3%83%88-for-motorsport"
										>
									<img
										src="/img/concept-cars/z4-g29/tuning/5212.jpg"
										class="img-fluid"
										alt="TIRES(画像01)"
									/>
								</a>
							</div>
							<div class="col-sm-3 col-6">
								<span class="badge badge-dark">シート</span>
								<h5>
									<a
										href="https://laptorr.com/collections/interior/products/522-00702-%E3%82%B7%E3%83%BC%E3%83%88%E3%83%AC%E3%83%BC%E3%83%AB-%E3%82%BC%E3%83%AD%E3%82%AA%E3%83%95%E3%82%BB%E3%83%83%E3%83%88-for-motorsport"
										>LAPTORR SEAT RAIL ZERO OFFSET</a
									>
								</h5>
								<h6>ラプタ シートレール ゼロオフセット</h6>
								<p>
									シートとステアリングとのオフセットをゼロ。メーカーに比べレールの強度アップされている。
								</p>
							</div>
						</div>
					</div>

					<!-- Blog Sidebar Area -->
					<!--<div class="col-12 col-lg-4">
						<Sidebar></Sidebar>
					</div>-->
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import PhotoSwipe from "photoswipe/dist/photoswipe.min.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.min.js";

export default {
	title: "G29-Z4 ASSIST Z4",
	components: {
		//Sidebar,
	},
	mounted: function () {
		const setup = () => {
			// 画像リストを作成
			const items = Array.from(document.querySelectorAll("img[data-size]")).map(
				(i) => {
					const wh = i.getAttribute("data-size").split("x");
					return {
						src: i.getAttribute("src"),
						w: parseInt(wh[0], 10),
						h: parseInt(wh[1], 10),
					};
				}
			);
			// photoswipe.htmlで定義したルート
			const pswpElement = document.querySelector(".pswp");
			// 画像クリック時にギャラリーが表示されるようにする
			document.querySelectorAll("img[data-size]").forEach((e, i) => {
				e.onclick = () => {
					const gallery = new PhotoSwipe(
						pswpElement,
						PhotoSwipeUI_Default,
						items,
						{
							shareEl: false,
							index: i,
						}
					);
					gallery.init();
				};
			});
		};
		setup();
	},
};
</script>

<style scoped lang="scss">
#main-info {
	background-color: #152535;
	h2 {
		font-size: 3rem;
		color: #fff;
	}
	h3 {
		padding: 0.5rem;
		border-bottom: 3px dotted #fff;
		color: #fff;
	}
	p {
		margin: 0 0 1rem 0;
		color: #fff;
	}
	table {
		th,
		td {
			color: #fff;
		}
	}
}
#achievement {
	.title {
		margin-bottom: 1rem;
		padding: 1rem 1rem 0.5rem 1rem;
		border-bottom: 2px solid #152535;
	}
}
#photo_grallery {
	p.caution {
		color: #152535;
		font-size: 0.8rem;
	}
}
#movies {
	small {
		font-size: 0.8rem;
	}
}
#spec {
	h4 {
		font-size: 1rem;
		padding: 1rem;
		border-bottom: 3px dotted #152535;
		background: #f4f4f4;
	}
	h5 {
		margin-bottom: 1.5rem;
		color: #152535;
		font-size: 1rem;
		font-weight: 500;
		a {
			color: #152535;
			font-size: 1rem;
			text-decoration: underline;
		}
	}
	h6 {
		padding: 0.6rem;
		color: #152535;
		font-size: 0.8rem;
		background: #f4f4f4;
	}
	p {
		color: #152535;
		font-size: 0.8rem;
	}
}
</style>
